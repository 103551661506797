import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import React, { useState } from 'react'
import { Link } from "react-router-dom";

import nnwCompensa from "../assets/img/nnw/compensa1.png";
import nnwInterrisk from "../assets/img/nnw/interrisk1.png";
import nnwWiener from "../assets/img/nnw/wiener1.png";
import nnwUniqa from "../assets/img/nnw/uniqa1.png";
import nnwAllianz from "../assets/img/nnw/allianz.png";
import nnwPZU from "../assets/img/nnw/pzu.png";
import nnwTUZ from "../assets/img/nnw/tuz.png";
import nnwWarta from "../assets/img/nnw/warta1.png";
import nnwLink4 from "../assets/img/nnw/link4.png";

export const NNWForm = () => {


  const [wybory, setWybory] = useState("");





  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const submitForm = () => { };

  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    pesel: '',
    regNr: '',
    startDate: '',
    drivingLicenseDate: '',
    code: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Wyślij');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
  }

  const handleSubmitShort = async (e) => {
    e.preventDefault();
    setButtonText("Wysyłanie...");
    let response = await fetch("http://158.101.165.21:5000/projects", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    setButtonText("Wyślij");
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.code == 200) {
      setStatus({ succes: true, message: 'Wiadomość wysłana' });
    } else {
      setStatus({ succes: false, message: 'Coś poszło nie tak, spróbuj później.' });
    }
  };




  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <br></br> <h2>Ubezpieczenia NNW dla przedszkolaków, uczniów i studentów</h2>
                  <p>Chroń siebie i bliskich w trakcie nauki, praktyk oraz w drodze na uczelnię. Zapewnij sobie wsparcie w przypadku uszczerbku na zdrowiu.<br></br> </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    {/* <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Skrócony formularz</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Pełny formularz</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Dowód rejestracyjny</Nav.Link>
                      </Nav.Item>
                    </Nav> */}
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <section className="contact" id="connect">
                          <div className="kontaktFull">
                            <form onSubmit={handleSubmitShort}>
                              <Row>
                                <Col size={12} className="px-1">
                                  <span style={{ fontSize: "20px" }}>Wybierz gotowy wariant ubezpieczenia dostarczany przez najpewniejsze firmy, dopasowany do Twoich indywidualnych potrzeb!</span>
                                </Col><br></br><br></br><br></br><hr />
                                <div id="choose" style={{ fontSize: "20px" }}>Kliknij, aby wybrać ofertę:</div><br></br><br></br>
                                <div class="container">
                                  <a target="_blank" href="https://www.warta.pl/kalkulator/ubezpieczenie-szkolne-nnw">
                                    <img src={nnwWarta} class="nnw-img" /></a><br></br>
                                  <a target="_blank" href="http://nnwszkolne.compensa.pl/#NNWInsurance/StartScreen?guid=4bd9b665-0d9e-42a7-b2a1-a4448bf69292">
                                    <img src={nnwCompensa} class="nnw-img" href="http://onet.pl" /></a><br></br>
                                  <a target="_blank" href="https://klient.interrisk.pl/EduPlusOfertaIndywidualna?agent=N1dRS0hE">
                                    <img src={nnwInterrisk} class="nnw-img" /></a><br></br>
                                  <a target="_blank" href="https://sprzedaz.wiener.pl/ubezpieczone-dziecko/r/30060">
                                    <img src={nnwWiener} class="nnw-img" /></a><br></br>
                                  <a target="_blank" href="https://www.uniqa.pl/kalkulator/ubezpieczenie-szkolne-nnw/insurance?PartnerType=PA&PartnerId1=581767&PartnerId2=LP">
                                    <img src={nnwUniqa} class="nnw-img" /></a><br></br>
                                  <a target="_blank" href="https://www.strefannw.pl/ ">
                                    <img src={nnwAllianz} class="nnw-img" /></a><br></br>
                                  <a target="_blank" href="https://moje.pzu.pl/sales/generic/education">
                                    <img src={nnwPZU} class="nnw-img" /></a><br></br>
                                  <a target="_blank" href="https://tuz.pl/ubezpieczenia-szkolne/?kod-znizkowy=11617489/P">
                                    <img src={nnwTUZ} class="nnw-img" /></a><br></br>
                                  <a target="_blank" href="https://kalkulatory.link4.pl/flow/link4mamaCalculator/l4d/dzieci?state=0-0-0&partner_id=143&source_id=143166">
                                    <img src={nnwLink4} class="nnw-img" /></a><br></br>


                                </div>
                                <hr></hr>

                                {
                                  status.message &&
                                  <Col>
                                    <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                                  </Col>
                                }
                              </Row>
                            </form>
                          </div></section>
                      </Tab.Pane>

                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section >
  )
}
