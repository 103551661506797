//import logo from './logo.png';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { VehicleForm } from "./components/VehicleForm";
import { NNWForm } from "./components/NNWForm";
import { useState } from "react";
import { Tooltip, TooltipProvider } from 'react-tooltip';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

function App() {
  const [vehicleBool, setvehicleBool] = useState(true);
  const [NNWBool, setNNWBool] = useState(true);

  function togglevehicleBool() {
    setvehicleBool(!vehicleBool)
  }

  function toggleNNWBool() {
    setNNWBool(!NNWBool)
  }

  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Switch>
          <Route exact path="/nnw" component={NNWForm} />
          <Route exact path="/" render={() => (
            vehicleBool ? (
              <>
                {NNWBool ? (
                  <Banner togglevehicleBool={togglevehicleBool} toggleNNWBool={toggleNNWBool} />
                ) : (
                  <NNWForm />
                )}
                <Footer />
              </>
            ) : (
              <TooltipProvider>
                <VehicleForm />
                <Footer />
              </TooltipProvider>
            )
          )} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

function MainSite(props) {
  return (
    <div>
      <button onClick={props.togglevehicleBool}>Button</button>
    </div>
  )
}

export default App;
