import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import React, { useState } from 'react'
import { Tooltip, TooltipProvider } from 'react-tooltip';
var oc_value_to_send = false;
var ac_value_to_send = false;
var nnw_value_to_send = false;
var szyby_value_to_send = false;
var assistance_value_to_send = false;
var zk_value_to_send = false;

var email_value_to_send = false;
var whatsapp_value_to_send = false;
var sms_value_to_send = false;

export const VehicleForm = () => {


  const [selected, setSelected] = useState(null);

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const [oc_value, setOc] = useState(false); // stan dla checkboxa OC
  const [ac_value, setAc] = useState(false);
  const [nnw_value, setNnw] = useState(false);
  const [szyby_value, setSzyby] = useState(false);
  const [assistance_value, setAssistance] = useState(false);
  const [zk_value, setZk] = useState(false);

  const [email_value, setEmail] = useState(false);
  const [whatsapp_value, setWhatsapp] = useState(false);
  const [sms_value, setSms] = useState(false);

  const handleOcChange = (event) => { oc_value_to_send = event.target.checked; setOc((oc_value) => !oc_value); console.log(oc_value_to_send) };
  const handleAcChange = (event) => { ac_value_to_send = event.target.checked; setAc((ac_value) => !ac_value); console.log(ac_value_to_send) };
  const handleNnwChange = (event) => { nnw_value_to_send = event.target.checked; setNnw((nnw_value) => !nnw_value); console.log(nnw_value_to_send) };
  const handleSzybyChange = (event) => { szyby_value_to_send = event.target.checked; setSzyby((szyby_value) => !szyby_value); console.log(szyby_value_to_send) };
  const handleAssistanceChange = (event) => { assistance_value_to_send = event.target.checked; setAssistance((assistance_value) => !assistance_value); console.log(assistance_value_to_send) };
  const handleZkChange = (event) => { zk_value_to_send = event.target.checked; setZk((zk_value) => !zk_value); console.log(zk_value_to_send) };

  const handleEmailChange = (event) => { email_value_to_send = event.target.checked; setEmail((email_value) => !email_value); console.log(email_value_to_send) };
  const handleWhatsappChange = (event) => { whatsapp_value_to_send = event.target.checked; setWhatsapp((whatsapp_value) => !whatsapp_value); console.log(whatsapp_value_to_send) };
  const handleSmsChange = (event) => { sms_value_to_send = event.target.checked; setSms((sms_value) => !sms_value); console.log(sms_value_to_send) };



  const submitForm = () => {

  };

  var formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    pesel1: '',
    regNr: '',
    startDate: '',
    drivingLicenseDate1: '',
    pesel2: '',
    drivingLicenseDate2: '',
    code: '',
    address: '',
    zipCode: ''

  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Wyślij');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
      oc: oc_value_to_send,
      ac: ac_value_to_send,
      nnw: nnw_value_to_send,
      szyby: szyby_value_to_send,
      assistance: assistance_value_to_send,
      zk: zk_value_to_send,
      email_checkbox: email_value_to_send,
      whatsapp: whatsapp_value_to_send,
      sms: sms_value_to_send
    })
  }

  const handleSubmitShort = async (e) => {
    e.preventDefault();
    setButtonText("Wysyłanie...");
    let response = await fetch("http://158.101.165.21:5000/projects", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    setButtonText("Wyślij");
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.code == 200) {
      setStatus({ succes: true, message: 'Wiadomość wysłana' });
    } else {
      setStatus({ succes: false, message: 'Coś poszło nie tak, spróbuj później.' });
    }
  };




  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <br></br> <h2>Ubezpieczenia komunikacyjne</h2>
                  <p>Ubezpieczenie samochodu jest niezbędne, aby mieć pewność, że jesteś chroniony w razie wypadku lub innego nieprzewidzianego zdarzenia drogowego.<br></br> </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    {/* <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Skrócony formularz</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Pełny formularz</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Dowód rejestracyjny</Nav.Link>
                      </Nav.Item>
                    </Nav> */}
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <section className="contact" id="connect">
                          <div className="kontaktFull">
                            <form onSubmit={handleSubmitShort}>
                              <Row>
                                <Col size={12} className="px-1">
                                  <span style={{ fontSize: "20px" }}>Podaj niezbędne dane abyśmy mogli przygotować dla Ciebie atrakcyjną ofertę. Formularz jest krótki i zawiera wymagane informacje do szybkiego przygotowania najlepszej oferty ubezpieczenia.</span>
                                </Col><br></br><br></br><br></br><hr />
                                {<Col size={12} sm={12} className="">
                                  {/* <input type="checkbox" value="OC" name="oc_checkbox" style={{ width: "50px", height: "50px" }} /> <label for="checkbox">OC</label>
                                    <input type="checkbox" value="AC" name="ac_checkbox" /> <label for="checkbox">AC</label>
                                    {/* <input type="checkbox" value="NNW" name="nnw_checkbox" /> <label for="checkbox">NNW</label>
                                    <input type="checkbox" value="Szyby" name="szyby_checkbox" /> <label for="checkbox">Szyby</label>
                                    <input type="checkbox" value="Opony" name="opony_checkbox" /> <label for="checkbox">Opony</label>
                                    <input type="checkbox" value="Assistance" name="assistance_checkbox" /> <label for="checkbox">Assistance</label>
                                    <input type="checkbox" value="Ochrona zniżek" name="ochrona_checkbox" /> <label for="checkbox">Ochrona zniżek</label>
                                    <input type="checkbox" value="Inne" name="inne_checkbox" /> <label for="checkbox">Inne</label> */}
                                </Col>}
                                {/* <hr></hr> */}<div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <Col size={12} sm={1} className="px-1">
                                    <input type="checkbox" value="oc_value" name="oc_checkbox" style={{ width: "50px", height: "50px" }} onChange={handleOcChange} /> <br></br><label for="checkbox">OC</label>
                                  </Col>
                                  <Col size={12} sm={1} className="px-1">
                                    <input type="checkbox" value="ac_value" name="ac_checkbox" style={{ width: "50px", height: "50px" }} onChange={handleAcChange} /> <br></br><label for="checkbox">AC</label>
                                  </Col>
                                  <Col size={12} sm={1} className="px-1">
                                    <input type="checkbox" value="nnw_value" name="nnw_checkbox" style={{ width: "50px", height: "50px" }} onChange={handleNnwChange} /> <br></br><label for="checkbox">NNW</label>
                                  </Col>
                                  <Col size={12} sm={1} className="px-1">
                                    <input type="checkbox" value="szyby_value" name="szyby_checkbox" style={{ width: "50px", height: "50px" }} onChange={handleSzybyChange} /> <br></br><label for="checkbox">Szyby</label>
                                  </Col>
                                  <Col size={12} sm={1} className="px-1">
                                    <input type="checkbox" value="assistance_value" name="assistance_checkbox" style={{ width: "50px", height: "50px" }} onChange={handleAssistanceChange} /> <br></br><label for="checkbox">Assistance</label>
                                  </Col>
                                </div><hr></hr>
                                <span>Ilość właścicieli:</span><br></br><br></br>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <Col size={12} sm={1} className="px-1">
                                    <input type="radio" value="1" name="owner_count" onChange={handleChange} style={{ width: "50px", height: "50px" }} /> <br></br><label for="checkbox">1</label>
                                  </Col>
                                  <Col size={12} sm={1} className="px-1">
                                    <input type="radio" value="2" name="owner_count" onChange={handleChange} style={{ width: "50px", height: "50px" }} /> <br></br><label for="checkbox">2</label>
                                  </Col></div><hr></hr>


                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.pesel1} placeholder="PESEL" onChange={(e) => onFormUpdate('pesel1', e.target.value)} required />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.drivingLicenseDate1} placeholder="Data zdania prawa jazdy (lub wpisz brak)" onChange={(e) => onFormUpdate('drivingLicenseDate1', e.target.value)} required />
                                </Col>

                                {selected === "2" && (
                                  <Col size={12} sm={6} className="px-1">
                                    <input type="text" value={formDetails.pesel2} placeholder="PESEL" onChange={(e) => onFormUpdate('pesel2', e.target.value)} />
                                  </Col>
                                )}
                                {selected === "2" && (
                                  <Col size={12} sm={6} className="px-1">
                                    <input type="text" value={formDetails.drivingLicenseDate2} placeholder="Data zdania prawa jazdy (lub wpisz brak)" onChange={(e) => onFormUpdate('drivingLicenseDate2', e.target.value)} />
                                  </Col>
                                )}


                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.startDate} placeholder="Data - od kiedy potrzebujesz ubezpieczenia" onChange={(e) => onFormUpdate('startDate', e.target.value)} required />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.regNr} placeholder="Numer rejestracyjny" onChange={(e) => onFormUpdate('regNr', e.target.value)} data-for="google" />
                                </Col>

                                <Col size={12} sm={12} className="px-1">
                                  <textarea rows="6" value={formDetails.message} placeholder="Dodatkowe uwagi - napisz co potrzebujesz dodatkowo lub pozostaw puste" onChange={(e) => onFormUpdate('message', e.target.value)} ></textarea>
                                </Col>
                                <Col size={12} sm={8} className="px-1">
                                  <input type="text" value={formDetails.address} placeholder="Adres zamieszkania" onChange={(e) => onFormUpdate('address', e.target.value)} data-for="google" />
                                </Col>
                                <Col size={12} sm={4} className="px-1">
                                  <input type="text" value={formDetails.zipCode} placeholder="Kod pocztowy" onChange={(e) => onFormUpdate('zipCode', e.target.value)} data-for="google" />
                                </Col>
                                <hr></hr>
                                <span>Preferowana forma kontaktu:</span><br></br><br></br>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                  <Col size={12} sm={1} className="px-1">
                                    <input type="checkbox" value="email_value" name="email_checkbox" style={{ width: "50px", height: "50px" }} onChange={handleEmailChange} /> <br></br><label for="checkbox">E-mail</label>
                                  </Col>
                                  <Col size={12} sm={1} className="px-1">
                                    <input type="checkbox" value="whatsapp_value" name="whatsapp_checkbox" style={{ width: "50px", height: "50px" }} onChange={handleWhatsappChange} /> <br></br><label for="checkbox">Whatsapp</label>
                                  </Col>
                                  <Col size={12} sm={1} className="px-1">
                                    <input type="checkbox" value="sms_value" name="sms_checkbox" style={{ width: "50px", height: "50px" }} onChange={handleSmsChange} /> <br></br><label for="checkbox">SMS</label>
                                  </Col></div><br></br><br></br><br></br><br></br><br></br>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="email" value={formDetails.email} placeholder="Adres e-mail" onChange={(e) => onFormUpdate('email', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="tel" value={formDetails.phone} placeholder="Telefon (Whatsapp/SMS)" onChange={(e) => onFormUpdate('phone', e.target.value)} />
                                </Col>


                                <hr></hr>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.code} placeholder="Kod polecający" onChange={(e) => onFormUpdate('code', e.target.value)} />
                                </Col>
                                <button type="submit"><span>{buttonText}</span></button>
                                {
                                  status.message &&
                                  <Col>
                                    <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                                  </Col>
                                }
                              </Row>
                            </form>
                          </div></section>
                      </Tab.Pane>

                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section >
  )
}
