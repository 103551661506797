import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import generali from "../assets/img/generali.png";
import allianz from "../assets/img/allianz.png";
import aviva from "../assets/img/aviva.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Współpracujemy z wieloma firmami ubezpieczeniowymi</h2>
              <p>Dzięki naszej współpracy z wieloma firmami ubezpieczeniowymi, jesteśmy w stanie zaoferować szeroki wybór opcji ubezpieczeniowych, w tym ubezpieczenia OC, AC, assistance i inne.

                Niezależnie od tego, czy jesteś początkującym kierowcą, czy posiadasz już doświadczenie, jesteśmy tutaj, aby Ci pomóc. Skontaktuj się z nami już dziś i pozwól nam pomóc Ci znaleźć najlepsze ubezpieczenie dla Ciebie.<br></br></p>
              <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                <div className="item">
                  <img src={generali} alt="Image" />
                  <h5>Generali</h5>
                </div>
                <div className="item">
                  <img src={allianz} alt="Image" />
                  <h5>Allianz</h5>
                </div>
                <div className="item">
                  <img src={aviva} alt="Image" />
                  <h5>Aviva</h5>
                </div>
                <div className="item">
                  <img src={generali} alt="Image" />
                  <h5>Generali</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
