import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import React, { useState } from 'react'

export const Projects = () => {


  const [wybory, setWybory] = useState("");





  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const submitForm = () => { };

  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    pesel: '',
    regNr: '',
    startDate: '',
    drivingLicenseDate: '',
    code: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Wyślij');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
  }

  const handleSubmitShort = async (e) => {
    e.preventDefault();
    setButtonText("Wysyłanie...");
    let response = await fetch("http://158.101.165.21:5000/projects", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    setButtonText("Wyślij");
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.code == 200) {
      setStatus({ succes: true, message: 'Wiadomość wysłana' });
    } else {
      setStatus({ succes: false, message: 'Coś poszło nie tak, spróbuj później.' });
    }
  };

  const handleSubmitFull = async (e) => {
    e.preventDefault();
    setButtonText("Wysyłanie...");
    let response = await fetch("http://158.101.165.21:5000/projects", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    setButtonText("Wyślij");
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.code == 200) {
      setStatus({ succes: true, message: 'Wiadomość wysłana' });
    } else {
      setStatus({ succes: false, message: 'Coś poszło nie tak, spróbuj później.' });
    }
  };


  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <br></br> <h2>Wybierz formularz kontaktowy</h2>
                  <p>Ubezpieczenie samochodu jest niezbędne, aby mieć pewność, że jesteś chroniony w razie wypadku lub innego nieprzewidzianego zdarzenia drogowego.<br></br> Nasza strona oferuje trzy rodzaje formularzy kontaktowych: skrócony, pełny lub przesłanie dowodu rejestracyjnego. Wybierz ten, który jest dla Ciebie najwygodniejszy.</p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Skrócony formularz</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Pełny formularz</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Dowód rejestracyjny</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <section className="contact" id="connect">
                          <div className="kontaktFull">
                            <form onSubmit={handleSubmitShort}>
                              <Row>
                                <Col size={12} className="px-1">
                                  <span>Formularz skrócony jest idealny dla osób, które potrzebują szybkiej i łatwej wypełnienia opcji. Zawiera tylko niezbędne informacje, takie jak dane osobowe i informacje o pojeździe. Dzięki temu proces wyboru ubezpieczenia jest prosty i szybki.</span>
                                </Col><br></br><br></br><br></br><hr />
                                <Col size={12} sm={12} className="py-1">
                                  <div style={{ display: 'flex' }}>
                                    <input type="checkbox" value="OC" name="oc_checkbox" /> <label for="checkbox">OC</label>
                                    <input type="checkbox" value="AC" name="ac_checkbox" /> <label for="checkbox">AC</label>
                                    <input type="checkbox" value="NNW" name="nnw_checkbox" /> <label for="checkbox">NNW</label>
                                    <input type="checkbox" value="Szyby" name="szyby_checkbox" /> <label for="checkbox">Szyby</label>
                                    <input type="checkbox" value="Opony" name="opony_checkbox" /> <label for="checkbox">Opony</label>
                                    <input type="checkbox" value="Assistance" name="assistance_checkbox" /> <label for="checkbox">Assistance</label>
                                    <input type="checkbox" value="Ochrona zniżek" name="ochrona_checkbox" /> <label for="checkbox">Ochrona zniżek</label>
                                    <input type="checkbox" value="Inne" name="inne_checkbox" /> <label for="checkbox">Inne</label>
                                  </div>
                                </Col>
                                <hr></hr>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.firstName} placeholder="Imię" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.lastName} placeholder="Nazwisko" onChange={(e) => onFormUpdate('lastName', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="email" value={formDetails.email} placeholder="Adres email" onChange={(e) => onFormUpdate('email', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="tel" value={formDetails.phone} placeholder="Telefon" onChange={(e) => onFormUpdate('phone', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.pesel} placeholder="PESEL" onChange={(e) => onFormUpdate('pesel', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.regNr} placeholder="Numer rejestracyjny" onChange={(e) => onFormUpdate('regNr', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.startDate} placeholder="Data - początek ubezpieczenia" onChange={(e) => onFormUpdate('startDate', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.drivingLicenseDate} placeholder="Data zdania prawa jazdy" onChange={(e) => onFormUpdate('drivingLicenseDate', e.target.value)} />
                                </Col>
                                <Col size={12} className="px-1">
                                  <textarea rows="6" value={formDetails.message} placeholder="Dodatkowe uwagi" onChange={(e) => onFormUpdate('message', e.target.value)} ></textarea>
                                </Col>
                                <hr></hr>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.code} placeholder="Kod polecający" onChange={(e) => onFormUpdate('code', e.target.value)} />
                                </Col>
                                <button type="submit"><span>{buttonText}</span></button>
                                {
                                  status.message &&
                                  <Col>
                                    <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                                  </Col>
                                }
                              </Row>
                            </form>
                          </div></section>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">

                        <section className="contact" id="connect">
                          <div className="kontaktFull">
                            <form onSubmit={handleSubmitFull}>
                              <Row>
                                <Col size={12} className="px-1">
                                  <span>Wypełnij ten formularz jeśli preferujesz podanie wszystkich danych od razu. Upewnij się, że podane dane są prawidłowe.</span>
                                </Col><br></br><br></br><br></br><hr />
                                <Col size={12} sm={12} className="py-1">
                                  <div style={{ display: 'flex' }}>
                                    <input type="checkbox" value="oc" name="oc" checked={(e) => onFormUpdate('oc', e.target.checked)} /> OC
                                    <input type="checkbox" value="ac" name="ac" /> AC
                                    <input type="checkbox" value="nnw" name="nnw" /> NNW
                                    <input type="checkbox" value="szyby" name="szyby" /> Szyby
                                    <input type="checkbox" value="opony" name="opony" /> Opony
                                    <input type="checkbox" value="assistance" name="assistance" /> Assistance
                                    <input type="checkbox" value="ochrona zniżek" name="ochrona" /> Ochrona zniżek
                                    <input type="checkbox" value="inne" name="inne" /> Inne
                                  </div>
                                </Col>
                                <hr></hr>
                                <Col size={12} sm={12} className="px-1">
                                  <label>
                                    Rodzaj pojazdu:
                                    <select onChange={(e) => onFormUpdate('vehicleType', e.target.value)}>
                                      <option value="" selected="" disabled="">Wybierz</option>
                                      <option value="Samochód osobowy">Samochód osobowy</option>
                                      <option value="Samochód ciężarowy do 3,5t">Samochód ciężarowy do 3,5t</option>
                                      <option value="Samochód ciężarowy powyżej 3,5t">Samochód ciężarowy powyżej 3,5t</option>
                                      <option value="Ciągnik samochodowy">Ciągnik samochodowy</option>
                                      <option value="Samochód specjalny">Samochód specjalny</option>
                                      <option value="Samochód kempingowy">Samochód kempingowy</option>
                                      <option value="Samochodowy inny">Samochodowy inny</option>
                                      <option value="Ciągnik rolniczy">Ciągnik rolniczy</option>
                                      <option value="Przyczepa/Naczepa">Przyczepa/Naczepa</option>
                                      <option value="Motocykl">Motocykl</option>
                                      <option value="Pojazd wolnobieżny">Pojazd wolnobieżny</option>
                                    </select>
                                  </label>
                                </Col><br></br><br></br>
                                <Col size={12} sm={12} className="px-1">
                                  <label>
                                    Rodzaj skrzyni biegów:
                                    <select onChange={(e) => onFormUpdate('biegiType', e.target.value)}>
                                      <option value="" selected="" disabled="">Wybierz</option>
                                      <option value="Manualna">Manualna</option>
                                      <option value="Automatyczna">Automatyczna</option>
                                      <option value="Brak">Brak</option>
                                    </select>
                                  </label>
                                </Col>
                                <br /><br />
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.firstName} placeholder="Imię" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.lastName} placeholder="Nazwisko" onChange={(e) => onFormUpdate('lastName', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="email" value={formDetails.email} placeholder="Adres email" onChange={(e) => onFormUpdate('email', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="tel" value={formDetails.phone} placeholder="Telefon" onChange={(e) => onFormUpdate('phone', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.pesel} placeholder="PESEL" onChange={(e) => onFormUpdate('pesel', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.address} placeholder="Adres (włącznie z kodem pocztowym)" onChange={(e) => onFormUpdate('address', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.regNr} placeholder="Nr rejestracyjny [Pole A]" onChange={(e) => onFormUpdate('regNr', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.startDate} placeholder="Data - początek ubezpieczenia" onChange={(e) => onFormUpdate('rstartDate', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.drivingLicenseDate} placeholder="Data zdania prawa jazdy" onChange={(e) => onFormUpdate('drivingLicenseDate', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.marka} placeholder="Marka [Pole D.1]" onChange={(e) => onFormUpdate('marka', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.model} placeholder="Model [Pole D.3]" onChange={(e) => onFormUpdate('model', e.target.value)} />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.przebieg} placeholder="Aktualny przebieg [km]" onChange={(e) => onFormUpdate('przebieg', e.target.value)} />
                                </Col>
                                <Col size={12} className="px-1">
                                  <textarea rows="6" value={formDetails.text} placeholder="Dodatkowe uwagi" onChange={(e) => onFormUpdate('text', e.target.value)} ></textarea>
                                </Col>
                                <hr></hr>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.code} placeholder="Kod polecający" onChange={(e) => onFormUpdate('code', e.target.value)} />
                                </Col>
                                <button type="submit"><span>{buttonText}</span></button>
                              </Row>
                            </form>
                          </div></section>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <section className="contact" id="connect">
                          <div className="kontaktFull">
                            <form>
                              <Col size={12} className="px-1">
                                <span>Nie chcesz wypełniać samodzielnie wszystkich danych? Po prostu podeślij zdjęcia dowodu rejestracyjnego (2 pliki). Jeśli właściciel jest inny niż podany na dowodzie, prosimy podać właściwe dane w polu z dodatkowymi uwagami.</span>
                              </Col><hr />
                              <Col size={12} sm={12} className="py-1">
                                <div style={{ display: 'flex' }}>
                                  <input type="checkbox" value="oc" name="oc" checked={(e) => onFormUpdate('oc', e.target.checked)} /> OC
                                  <input type="checkbox" value="ac" name="ac" /> AC
                                  <input type="checkbox" value="nnw" name="nnw" /> NNW
                                  <input type="checkbox" value="szyby" name="szyby" /> Szyby
                                  <input type="checkbox" value="opony" name="opony" /> Opony
                                  <input type="checkbox" value="assistance" name="assistance" /> Assistance
                                  <input type="checkbox" value="ochrona zniżek" name="ochrona" /> Ochrona zniżek
                                  <input type="checkbox" value="inne" name="inne" /> Inne
                                </div>
                              </Col>
                              <hr></hr>
                              <Row>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" placeholder="Imię" />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" placeholder="Nazwisko" />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="email" placeholder="Adres e-mail" />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="tel" placeholder="Telefon" />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="file" />
                                </Col>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="file" />
                                </Col>
                                <Col size={12} className="px-1">
                                  <textarea rows="6" value={formDetails.text} placeholder="Dodatkowe uwagi" onChange={(e) => onFormUpdate('text', e.target.value)} ></textarea>
                                </Col>
                                <hr></hr>
                                <Col size={12} sm={6} className="px-1">
                                  <input type="text" value={formDetails.code} placeholder="Kod polecający" onChange={(e) => onFormUpdate('code', e.target.value)} />
                                </Col>
                                <button type="submit"><span>Wyślij</span></button>
                              </Row>
                            </form>
                          </div></section>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
